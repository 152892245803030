import React from 'react'
import styled from 'styled-components'

import { theme } from '@/styles/theme'
import { Layout } from '@/components/layouts/Layout'
import { Title } from '@/components/elements/Title'
import { BreadCrumbs } from '@/components/layouts/Breadcrumbs'
import { Seo } from '@/components/Seo'
import { TitleWrapper } from '@/styles/common'

export default function lost() {
  const breadCrumbs = [
    { name: 'トップ', path: '/' },
    { name: 'お問い合わせ', path: '/contact' },
    { name: '携帯電話を紛失した場合', path: '' },
  ]

  return (
    <Layout isForm>
      <Seo
        title="紛失時のお手続き - お問い合わせ"
        canonical="https://su-pay.jp/contact/lost/"
        description="携帯電話を紛失した場合は、こちらからSU-PAYの一時停止をご依頼ください。"
      />
      <Title as="h1" bg>
        お問い合わせ
      </Title>
      <PageContents>
        <BreadCrumbs breadCrumbs={breadCrumbs} />
        <TitleWrapper>
          <Title as="h2" color={theme.colors.supayBlue}>
            携帯電話を紛失した場合
          </Title>
        </TitleWrapper>
        <Contents>
          <p>
            コールセンターにご連絡の上、一時利用停止をご依頼ください。ただし、お申し出がなかった場合や、お申し出後、利用停止が反映されるまでに利用等された部分については、お客さまのご負担および責任となります。
          </p>
        </Contents>
        <ContactWrapper>
          <Title as="h2" color={theme.colors.supayBlue}>
            お問い合わせ先
          </Title>
          <ContactOffice>
          株式会社SU-PAY
          </ContactOffice>
          <ContactNumber>電話番号：0570-078-135</ContactNumber>
          <ContactTime>
            <p>受付時間：24時間</p>
            <p>※通話料は、お客様負担です。</p>
          </ContactTime>
        </ContactWrapper>
      </PageContents>
    </Layout>
  )
}

const PageContents = styled.div`
  width: ${(props) => props.theme.containerWidth};

  margin: 30px auto 0;

  @media screen and (max-width: 1047px) {
    width: 100%;

    padding: 0 ${(props) => props.theme.containerMargin};
    margin: 12px auto 0;
  }
`

const Contents = styled.div`
  font-size: 18px;

  @media screen and (max-width: 1047px) {
    font-size: 16px;
  }
`

const ContactWrapper = styled.div`
  margin-top: 123px;

  h2 {
    line-height: 46px;
    text-align: left;
  }

  @media screen and (max-width: 1047px) {
    margin-top: 60px;
    h2 {
      line-height: 24px;
      font-size: 20px;
    }
  }
`

const ContactOffice = styled.div`
  margin-top: 70px;
  font-size: 18px;

  @media screen and (max-width: 1047px) {
    margin-top: 22px;
    font-size: 14px;
  }
`

const ContactNumber = styled.div`
  margin-top: 26px;
  font-size: 27px;
  font-weight: bold;
  line-height: 38px;

  @media screen and (max-width: 1047px) {
    margin-top: 14px;
    font-size: 18px;
    line-height: 22px;
  }
`

const ContactTime = styled.div`
  margin-top: 50px;
  font-size: 18px;
  line-height: 28px;

  @media screen and (max-width: 1047px) {
    margin-top: 22px;
    font-size: 14px;
    line-height: 22px;
  }
`
